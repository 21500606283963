<template>
  <b-container class="orders-container">
    <b-alert v-model="alert.model" :variant="alert.variant" dismissible fade>
      {{ alert.text }}
    </b-alert>
    <h1 v-if="!isRbAdmin">Orders</h1>
    <b-row class="container-search">
      <GenericSearch :value="form.input" @updateSearch="updateSearch" class="mr-2" />
      <RbButton v-b-toggle.advanced-search variant="secondary" size="sm"> Advanced Search </RbButton>
      <div class="pl-5">
        <CreateCashSaleModal v-if="canCreateCashSales" size="sm" />
      </div>
    </b-row>
    <b-collapse id="advanced-search">
      <b-row class="container-search">
        <b-col>
          <OrderFilters
            @orderFilterChanged="onOrderFilterChanged"
            :defaultStartDateTime="form.filter.startDateTime"
            :defaultEndDateTime="form.filter.endDateTime"
          />
        </b-col>
      </b-row>
    </b-collapse>
    <div class="overflow-x-hidden">
      <div class="col">
        <div class="table-overflow">
          <GenericTable
            :columns="columns"
            :data="data"
            :actions="true"
            :pagination="pagination"
            :totalName="totalName"
            @updateCurrentPage="updateCurrentPage"
            @sort="sort"
          >
            <template #id="{ row }">
              <router-link :to="{ path: `/order/${row.uuid}` }">
                <p class="text-gray-900 underline text-left">{{ row.id }}</p>
              </router-link>
            </template>
            <template #amountPaidCents="{ row }">
              <p>{{ row.amountPaidCents }}</p>
            </template>

            <template #numTickets="{ row }">
              <p class="text-right">{{ row.numTickets }}</p>
            </template>
            <template #status="{ row }">
              <b-badge variant="success" v-if="row.status === 'ACTIVE'">Active</b-badge>
              <b-badge variant="secondary" v-else-if="row.status === 'INACTIVE'">Inactive</b-badge>
              <b-badge variant="light" v-else-if="row.status === 'PENDING'">Pending</b-badge>
              <b-badge variant="dark" v-else-if="row.status === 'VOID'">Void</b-badge>
              <b-badge variant="danger" v-else-if="row.status === 'FAILED'">Error</b-badge>
            </template>
            <template #actions="{ row }">
              <div class="action-button">
                <span v-b-modal="'edit-order-' + row.id"><i class="far fa-edit" /></span>
                <EditOrderModalV2 @orderUpdated="refreshTable" :modalId="row.id" :orderId="row.uuid" />
              </div>
            </template>
          </GenericTable>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import OrderServiceV2 from '@/lib/order-service-v2';
import CreateCashSaleModal from '@/components/modals/CreateCashSaleModal';
import EditOrderModalV2 from '@/components/EditOrderModalV2';
import { isStripePurchaseTimedOut, setStripePurchaseTimedOutError } from '@/lib/stripe-purchase-timeout';
import GenericTable from '@/components/GenericTable.vue';
import OrderFilters from '@/components/OrderFilters';
import GenericSearch from '@/components/GenericSearch.vue';
import { featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';

export default {
  name: 'Orders',
  components: {
    GenericSearch,
    GenericTable,
    CreateCashSaleModal,
    EditOrderModalV2,
    OrderFilters
  },
  props: ['isRbAdmin'],
  async created() {
    const search = this.$route.query.search;
    if (search) {
      this.form.input = search;
      this.routeQuery = true;
    }
    this.canCreateCashSales = await featureEnabled(LaunchDarklyKeys.CreateCashSales);
  },
  async mounted() {
    this.getOrders();
    this.showDevices = await featureEnabled(LaunchDarklyKeys.ViewDevices);
  },
  watch: {
    'form.input': function () {
      if (this.form.input?.trim().length >= 3) {
        this.refreshTable();
      }
    },
    form: {
      handler() {
        this.refreshTable();
      },
      deep: true
    }
  },
  computed: {
    form() {
      return this.$store.getters.getForm;
    }
  },
  data() {
    return {
      alert: {
        text: '',
        variant: '',
        model: false
      },
      showCreateCashSale: false,
      organizationCountry: null,
      columns: [
        {
          name: 'id',
          label: 'ID',
          classes: 'w-auto'
        },
        { name: 'subscriptionId', label: 'Sub ID', classes: 'w-auto' },
        { name: 'batchId', label: 'Batch ID', classes: 'w-auto' },
        {
          name: 'createdAt',
          label: 'Created at',
          classes: 'w-auto',
          sortable: true
        },
        {
          name: 'name',
          label: 'Name',
          classes: 'w-auto',
          sortable: true
        },
        {
          name: 'email',
          label: 'Email',
          classes: 'w-auto',
          sortable: true
        },
        {
          name: 'phone',
          formatter: (val) => this.formatTelephone(val),
          classes: 'w-auto',
          label: 'Phone Number',
          sortable: true
        },
        { name: 'numTickets', label: 'Tickets', classes: 'w-auto' },
        {
          name: 'amountPaidCents',
          label: 'Amount',
          classes: 'w-auto',
          isCurrency: true,
          sortable: true
        },
        {
          name: 'status',
          label: 'Status',
          classes: 'w-auto'
        }
      ],
      data: [],
      sortBy: 'createdAt',
      sortDir: 'desc',
      pagination: {
        page: 0,
        pageSize: 10,
        total: 0
      },
      totalName: 'Orders',
      routeQuery: false,
      canCreateCashSales: false,
      showDevices: false
    };
  },
  methods: {
    updateSearch(input) {
      this.$store.commit('setSearchInput', input);
    },
    async refreshTable() {
      await this.getOrders();
      this.$root.$emit('bv::refresh::table', 'table');
    },
    onOrderFilterChanged() {
      this.refreshTable();
    },
    async getOrders() {
      try {
        const sessionUser = await getAuth().sessionUser();
        const organizationId = this.isRbAdmin ? undefined : sessionUser.organizationUuid;

        if (this.routeQuery) {
          const result = await OrderServiceV2.retrieveOrder(this.form.input.trim());

          return result ? [result] : [];
        }

        const params = {
          organizationId,
          search: this.form.input.trim().length > 0 ? this.form.input.trim() : undefined,
          eventId: this.$route.query.eventId || this.form.filter.event?.id || undefined,
          stationId: this.form.filter.station || undefined,
          deviceId: this.form.filter.device || undefined,
          status: this.$route.query.status || this.form.filter.status || undefined,
          from: this.form.filter.startDateTime || undefined,
          to: this.form.filter.endDateTime || undefined,
          batchId: this.$route.query.batchId || undefined,
          page: this.currentPage,
          pageSize: this.pageSize,
          sortDir: this.sortDir,
          sortBy: this.sortBy
        };

        if (this.$route.query.search) {
          delete params.from;
        }

        const { data, pagination } = await OrderServiceV2.listOrders(params);

        this.pagination = pagination;
        data.forEach((order) => {
          // if pending and old show as error
          if (isStripePurchaseTimedOut(order)) {
            setStripePurchaseTimedOutError(order);
          }
        });
        this.data = data.map((order) => {
          return {
            ...order,
            id: this.formatUuid(order.id),
            subscriptionId: this.formatUuid(order.subscriptionId),
            createdAt: this.formatDateTime(this.parseISO(order.createdAt)),
            name: order.firstName + ' ' + order.lastName,
            email: order.email,
            phone: this.formatTelephone(order.phone),
            numTickets: order.numTickets,
            amountPaidCents: this.formatCurrency(order.amountPaidCents / 100, { precision: 0 }),
            status: order.status,
            uuid: order.id
          };
        });
      } catch (error) {
        this.handleError(`Failed to load orders: ${error.message}`);
        return [];
      }
    },
    handleError(text) {
      this.alert.text = text;
      this.alert.model = true;
      this.alert.variant = 'danger';
    },
    formatTelephone(val) {
      if (val !== null) {
        return this.formatPhone(val);
      }

      return '';
    },
    async updateCurrentPage(page) {
      this.currentPage = page;
      await this.getOrders();
    },
    async sort(sortBy, sortDir) {
      this.sortBy = sortBy;
      this.sortDir = sortDir;
      await this.getOrders();
    }
  }
};
</script>

<style scoped>
.min-search-width {
  min-width: 20rem;
}
.orders-container {
  text-align: left;
  max-width: 90%;
}
.container-search {
  padding-bottom: 1rem;
  padding-left: 2rem;
}
.container-void {
  padding-top: 1rem;
}

.dashline {
  border-bottom: 1px dashed;
}

.action-button {
  display: flex;
  align-items: center;
  padding-right: 2rem;
}

@media screen and (max-width: 62em) {
  .orders-container {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }
}
</style>
